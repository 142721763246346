import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeView from '../views/HomeView.vue';

Vue.use(VueRouter);
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login/LoginView.vue'),
  },
  {
    path: '/setpaasword',
    name: 'setpassword',
    component: () => import(/* webpackChunkName: "about" */ '../views/setpassword/SetPasswordView.vue'),
  },
  {
    path: '/select',
    name: 'select',
    component: () => import(/* webpackChunkName: "about" */ '../views/select/SelectView.vue'),
  },
  {
    path: '/node',
    name: 'node',
    component: () => import(/* webpackChunkName: "about" */ '../views/query/NodeView.vue'),
  },
  {
    path: '/stat',
    name: 'stat',
    component: () => import(/* webpackChunkName: "about" */ '../views/query/StatView.vue'),
  },
  {
    path: '/downcommand',
    name: 'downcommand',
    // eslint-disable-next-line import/no-unresolved
    component: () => import(/* webpackChunkName: "about" */ '../views/query/downcommand.vue'),
  },
  {
    path: '/otaa',
    name: 'otaa',
    component: () => import(/* webpackChunkName: "about" */ '../views/query/OTAAView.vue'),
  },
  {
    path: '/rx',
    name: 'rx',
    component: () => import(/* webpackChunkName: "about" */ '../views/query/RxView.vue'),
  },
  {
    path: '/setnode',
    name: 'setnode',
    component: () => import(/* webpackChunkName: "about" */ '../views/setnode/SetView.vue'),
  },
  {
    path: '/installnode',
    name: 'installnode',
    // eslint-disable-next-line import/no-unresolved
    component: () => import(/* webpackChunkName: "about" */ '../views/query/installnode.vue'),
  },
  {
    path: '/installnode/calibrate',
    name: 'calibrate',
    // eslint-disable-next-line import/no-unresolved
    component: () => import(/* webpackChunkName: "about" */ '../views/query/calibrate.vue'),
  },
  {
    path: '/debugprojectlist',
    name: 'debugprojectlist',
    component: () => import(/* webpackChunkName: "about" */ '../views/project/debugproject/DebuglistViews.vue'),
  },
  {
    path: '/debugproject',
    name: 'debugproject',
    component: () => import(/* webpackChunkName: "about" */ '../views/project/debugproject/DebugViews.vue'),
  },
  {
    path: '/debugyesdata',
    name: 'debugyesdata',
    component: () => import(/* webpackChunkName: "about" */ '../views/project/debugproject/yesdata.vue'),
  },
  {
    path: '/debugerrdata',
    name: 'debugerrdata',
    component: () => import(/* webpackChunkName: "about" */ '../views/project/debugproject/errdata.vue'),
  },
  {
    path: '/debugnodeinfo',
    name: 'debugnodeinfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/project/debugproject/nodeinfo.vue'),
  },
  {
    path: '/noteinfo',
    name: 'noteinfo',
    // eslint-disable-next-line import/no-unresolved
    component: () => import(/* webpackChunkName: "about" */ '../views/project/alarm/noteinfo.vue'),
  },
  {
    path: '/notenumber',
    name: 'notenumber',
    // eslint-disable-next-line import/no-unresolved
    component: () => import(/* webpackChunkName: "about" */ '../views/project/alarm/notenumber.vue'),
  },
  {
    path: '/nodeaddr',
    name: 'nodeaddr',
    // eslint-disable-next-line import/no-unresolved
    component: () => import(/* webpackChunkName: "about" */ '../views/query/nodeaddr.vue'),
  },
  {
    path: '/gwlist',
    name: 'gwlist',
    // eslint-disable-next-line import/no-unresolved
    component: () => import(/* webpackChunkName: "about" */ '../views/query/gwlist.vue'),
  },
  {
    path: '/gwdistmap',
    name: 'gwdistmap',
    // eslint-disable-next-line import/no-unresolved
    component: () => import(/* webpackChunkName: "about" */ '../views/map/gwdistmap.vue'),
  },
  {
    path: '/monitorlist',
    name: 'monitorlist',
    // eslint-disable-next-line import/no-unresolved
    component: () => import(/* webpackChunkName: "about" */ '../views/monitor/applist.vue'),
  },
  {
    path: '/monitor/nodestatprolist',
    name: 'nodestatprolist',
    // eslint-disable-next-line import/no-unresolved
    component: () => import(/* webpackChunkName: "about" */ '../views/monitor/nodestatprolist.vue'),
  },
  {
    path: '/monitor',
    name: 'monitor',
    // eslint-disable-next-line import/no-unresolved
    component: () => import(/* webpackChunkName: "about" */ '../views/monitor/app.vue'),
  },
  {
    path: '/monitor/nodestatpro',
    name: 'nodestatpro',
    // eslint-disable-next-line import/no-unresolved
    component: () => import(/* webpackChunkName: "about" */ '../views/monitor/nodestatpro.vue'),
  },
  {
    path: '/afterSale',
    name: 'afterSale',
    // eslint-disable-next-line import/no-unresolved
    component: () => import(/* webpackChunkName: "about" */ '../views/query/afterSale.vue'),
  },
  {
    path: '/ems',
    name: 'ems',
    // eslint-disable-next-line import/no-unresolved
    component: () => import(/* webpackChunkName: "about" */ '../views/query/ems.vue'),
  },
  {
    path: '/gpsAppList',
    name: 'gpsAppList',
    // eslint-disable-next-line import/no-unresolved
    component: () => import(/* webpackChunkName: "about" */ '../views/map/gpsNode/appList.vue'),
  },
  {
    path: '/gpsApp',
    name: 'gpsApp',
    // eslint-disable-next-line import/no-unresolved
    component: () => import(/* webpackChunkName: "about" */ '../views/map/gpsNode/app.vue'),
  },
  {
    path: '/qc',
    name: 'qc',
    // eslint-disable-next-line import/no-unresolved
    component: () => import(/* webpackChunkName: "about" */ '../views/query/qc.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
