<script>
const exitloginshow = true;
const returnselectshow = true;
const setpassword = true;
const leftNavbar = false;
const InvalidToken = 'InvalidToken';
const InvalidParam = 'InvalidParam';
const NullData = 'NullData';
const SystemError = 'SystemError';
// const googleapi = 'AIzaSyAPrLbhDXqzlTkqi9AoGEZbEkUwDBsxbOI';
const request = '';
export default
{
  request,
  exitloginshow,
  returnselectshow,
  setpassword,
  InvalidToken,
  InvalidParam,
  NullData,
  SystemError,
  leftNavbar,
};
</script>
